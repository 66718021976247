input[type="file"] {
  display: none;
}
.customFileUpload {
  border: 1px solid #2585ca;
  background-color: #2585ca;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  flex: 0.15;
  border-radius: 8px;
}

.imageContainer{
  width: 100px;
  height: 100px;
  border: 1px solid #2585ca;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image{
  height: 80px;
  border-radius: 8px;
  width: 80px;
}