// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./colors.scss";
@import "./typography.scss";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "~bootstrap/scss/maps";

// 5. Include remainder of required parts
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/transitions";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here

.text-dark {
  color: $dark !important;
}

.active {
  background-color: $white !important;
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}

.bg-accent {
  background-color: $background-blue-accent !important;
}

.login-wrapper {
  min-height: 100vh;
}

td,
th {
  text-align: start;

  .col p {
    text-align: start;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: #e1e1e1;
  border-radius: 20px;
}

// .logo {
//   width: 100%;
// }

.section-label {
  color: $dark-grey;
  font-weight: 500;
  text-transform: uppercase;
}

.w-fit {
  width: fit-content;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  background: white;
  z-index: 10000;
}

.modal-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background: white;
  z-index: 10000;
}

.img-preview {
  height: 70px;
  width: auto;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.project-modal {
  .form-check,
  .form-switch {
    padding-left: 0 !important;
  }
}

.add-project-doc {
  width: 90px;
  height: 90px;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  background: $light;
  margin-top: 10px;
  color: $dark-grey;
  cursor: pointer;
}

.custom-form-control:focus {
  outline: none;
  box-shadow: none;
}

.sticky-table-cell {
  position: sticky;
  left: 0;
  z-index: 1;
}
.sticky-table-row {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.recharts-brush-traveller {
  cursor: default !important; /* Ensures the default arrow cursor is used */
}

.recharts-brush-slide {
  cursor: default !important; /* For the sliding area */
}

.recharts-brush .recharts-brush-texts text {
  display: none; /* Hides the left and right labels */
}


